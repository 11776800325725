/**
 * @flow
 * */
import React from 'react';

import settings from 'config/constants';
import { Redirect } from 'react-router-dom';

const AlertMonitor = React.lazy(() => import('./index'));

type Route = {
    path: string,
    name: string,
    exact: boolean,
    page: Function,
    subRoutes?: Array<Route>,
}

const subUrl = settings.PRODUCTS.CRMT.path;
const modulePath = `${subUrl}/alert-monitor`;

const ROUTES: Array<Route> = [
  { path: '/crmt', name: 'crmt', exact: true, page: () => <Redirect to={`/${modulePath}/game-anomalies`} /> },

  { path: `/${modulePath}/game-anomalies`, name: 'Game Anomalies', exact: true, page: AlertMonitor },
  { path: `/${modulePath}/game-anomalies:tab`, name: 'Game Anomalies tab', exact: true, page: AlertMonitor }
];

export default ROUTES;
