import PLAYERS_GROUPS_ROUTES from './PlayersGroup/routes';
import ALERT_ROUTES from './AlertMonitor/routes';
import PLAYERS_ROUTES from './Players/routes';

const getProjectState = (state) => state.CRMT;

const routes = [
  ...PLAYERS_ROUTES,
  ...PLAYERS_GROUPS_ROUTES,
  ...ALERT_ROUTES
];

export {
  getProjectState,
  routes
};
